import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../../../../styles/Dashboard/ManageInventory/AddEditItem/SelectTagsPanel.scss";
import { useManageInventory } from "../../../../hooks/index.js";


export default function SelectTagsPanel(props) {
  const { tags, setTags } = props;
  const manageInventory = useManageInventory();
  const { itemTagsList } = manageInventory;
  const [availableTags, setAvailableTags] = useState([]);

  useEffect(() => {
    let newItemTagList = [...itemTagsList];
    newItemTagList.forEach(itemTag => {
      itemTag.selected = false;
      tags.forEach(tag => {
        if (tag.name === itemTag.name) {itemTag.selected = true;}
      });
    });
    setAvailableTags(newItemTagList);
  }, [itemTagsList, tags]);

  const toggleSelectTag = (clickedTag) => {
    let newAvailableTags = [...itemTagsList];
    newAvailableTags.forEach(tag => {
      if (tag.name === clickedTag.name) {tag.selected = !tag.selected;}
    });
    setAvailableTags(newAvailableTags);

    // set the selected tag
    let newTags = [];
    newAvailableTags.forEach(tag => {
      if (tag.selected) {newTags.push(tag);}
    });
    setTags(newTags);
  };

  const handleTagSearch = (event) => {
    const tagName = event.target.value;
    let newAvailableTags = [];
    itemTagsList.forEach(tag => {
      if (tag.name.toLowerCase().includes(tagName.toLowerCase())) {
        newAvailableTags.push(tag);
      }
    });
    if (newAvailableTags.length) {setAvailableTags(newAvailableTags);}
  };

  return (
    <div className="select-tags-panel">
      <div className="tags-container">
        <input
          className="search-tag-input"
          type="text"
          onChange={handleTagSearch}
          placeholder="Search"
        />

        <button
          className="add-tag-btn"
          onClick={() => {}}
        >
          {/*<FontAwesomeIcon className="icon" icon={faPlus} />*/}
        </button>

        {availableTags.map((tag, index) => (
          <button
            key={`tag-${index}`}
            className="tag-btn"
            style={tag.selected ? {
              height: 25,
              border: "solid 1px rgba(0, 0, 0, 0.25)",
              color: "rgba(0, 0, 0, 0.5)",
              backgroundColor: "white"
            } : {backgroundColor: tag.color }}
            onClick={() => toggleSelectTag(tag)}
          >
            <p>{tag.name}</p>

            {tag.selected && (
              <div></div>
              // <FontAwesomeIcon className="icon" icon={faXmark} />
            )}
          </button>
        ))}
      </div>
    </div>
  );
}

SelectTagsPanel.propTypes = {
  tags: PropTypes.object.isRequired,
  setTags: PropTypes.func.isRequired
};