import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/Register/VerifyCredentials.scss";
import { useRegister } from "../../hooks/index.js";
import { CredVerifier, GoogleVerifier, PopUp } from "../../components/index.js";


export default function VerifyCredentials() {
  const register = useRegister();
  const {
    isUserExists,
    removeIsUserExistsPrompt,
    handleCheckEmailIdExists,
    handleEmailIdServerVerification,
    handleCheckMobileNumberExists,
    handleMobileNumberServerVerification
  } = register;
  const navigate = useNavigate();

  return (
    <div className="verify-credentials">
      {/* sign-up with email/mobile */}
      <CredVerifier
        onVerifyEmailIdClick={handleCheckEmailIdExists}
        onEmailIdTokenChange={handleEmailIdServerVerification}
        onVerifyMobileNumberClick={handleCheckMobileNumberExists}
        onMobileNumberTokenChange={handleMobileNumberServerVerification}
      />

      {/* sign-up with Google */}
      <div className="signup-with-google">
        <p>OR,</p>
        <GoogleVerifier
          onGoogleTokenChange={handleEmailIdServerVerification}
          authType="sign-up"
        />
        <p className="sign-in-text">
          Already have a ventory account?
          <Link to="/signin">Sign In</Link>
        </p>
      </div>

      {isUserExists && (
        <PopUp>
          <div className="sign-in-prompt">
            <p className="prompt-text">
              User Already Exists!
              <b>Sign in instead?</b>
            </p>

            <div className="action-button-container">
              <button
                className="cancel-button"
                onClick={removeIsUserExistsPrompt}
              >Cancel</button>
              <button
                className="submit-button"
                onClick={() => navigate("/")}
              >Sign in</button>
            </div>
          </div>
        </PopUp>
      )}
    </div>
  );
}
