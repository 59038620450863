import React from "react";
import PropTypes from "prop-types";
import "../../../../styles/Dashboard/ManageInventory/PreviewInventory/PreviewDescription.scss";


export default function PreviewDescription(props) {
  const { description } = props;

  return (
    <div className="preview-description">
      <p>{description}</p>
    </div>
  );
}

PreviewDescription.propTypes = {
  description: PropTypes.string.isRequired
};