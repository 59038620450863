import React from "react";
import PropTypes from "prop-types";
import "../styles/components/MaxWidthContainer.scss";


export default function MaxWidthContainer(props) {
  const { children, height } = props;

  return (
    <div
      className="max-width-container"
      style={{ height }}
    >
      {children}
    </div>
  );
}

MaxWidthContainer.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string.isRequired
};

MaxWidthContainer.defaultProps = {
  height: undefined
};