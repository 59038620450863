import { getAccessTokenFromLocalStorage, isTokenDateValid } from "../utils/index.js";

export const request = async (url, { method, headers, body }, enableJWT = true, returnResponseText = false) => {
  const config = {};
  // verify token if the request is jwt enabled
  if (enableJWT) {
    const access_token = await getAccessTokenFromLocalStorage();
    if (!isTokenDateValid(access_token)){
      // if token is not valid then return
      return { success: false, status: 401 };
    }
    // if token is valid include it in header
    config.headers = { Authorization: access_token };
  }

  // populate the config with method, header and body
  if (method) {config.method = method;}
  if (headers) {config.headers = { ...config.headers, ...headers };}
  if (body) {config.body = body;}

  try {
    // fetch request
    const response = await fetch(url, config);
    if (response.ok) {
      try {
        let data;
        if (returnResponseText) {
          data = await response.text();
        } else {
          data = await response.json();
        }
        return { data: data, success: true, status: response.status };
      } catch (error) {}
    }
    return { success: false, status: response.status };
  }
  catch (error) {}
  return { success: false, status: 400 };
};