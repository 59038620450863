import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "../styles/components/ImageCropInput.scss";
import ActionButton from "./ActionButton.js";
import { ImageCropUtils } from "../utils/index.js";


export default function ImageCropInput(props) {
  const {
    removePopUpFunction, aspectRatio, placeholder,
    image, setImage, imageUrl, setImageUrl
  } = props;
  const [unFinalisedImage, setUnFinalisedImage] = useState(null);
  const [unFinalisedImageUrl, setUnFinalisedImageUrl] = useState("");
  const [minCropLength, setMinCropLength] = useState({ height: 0, width: 0 });
  const [imageCropperObject, setImageCropperObject] = useState(null);
  const [isImageDimensionInvalid, setIsImageDimensionInvalid] = useState(false);
  const [imageContainerDimensions, setImageContainerDimensions] = useState({ height: 0, width: 0 });
  const [cropperInfo, setCropperInfo] = useState({ height: 0, topOffset: 0, leftOffset: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOperation, setDragOperation] = useState("");
  const imageContainerRef = useRef(null);
  const cropperContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  const touchRef = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (image) {
      setUnFinalisedImage(image);
      setUnFinalisedImageUrl(imageUrl);
    }
    // set image container dimensions
    if (imageContainerRef.current) {
      setImageContainerDimensions({
        height: imageContainerRef.current.clientHeight,
        width: imageContainerRef.current.clientWidth
      });
    }
    // set minimum crop length
    setMinCropLength({
      height: (aspectRatio < 1) ? (500 * (1/aspectRatio)) : 500,
      width: (aspectRatio < 1) ? 500 : (500 * aspectRatio)
    });
  }, []);

  useEffect(() => {
    // image crop controller
    if (unFinalisedImage) {
      const handleImageCrop = async () => {
        // create imageCropperObject
        const imageCropperObject = new ImageCropUtils(
          unFinalisedImage,
          imageContainerDimensions,
          minCropLength.height,
          aspectRatio
        );
        // set image cropper object
        setImageCropperObject(imageCropperObject);
        // set the image dimensions
        await imageCropperObject.setDimensions();
        // get actual image dimensions
        const originalImageDimensions = imageCropperObject.getOriginalImageDimensions();
        // check if input image dimensions are valid
        if (originalImageDimensions.height < minCropLength.height ||
          originalImageDimensions.width < minCropLength.width
        ) {
          // display the error
          setIsImageDimensionInvalid(true);
          setUnFinalisedImage(null);
          setUnFinalisedImageUrl("");
        } else {
          // set the dimensions
          setIsImageDimensionInvalid(false);
          // check if the image is croppable or not
          if (originalImageDimensions.height !== minCropLength.height ||
            originalImageDimensions.width !== minCropLength.width
          ) {
            setCropperInfo(imageCropperObject.getCropperInfo());
          }
        }
      };
      handleImageCrop().then();
    }
  }, [unFinalisedImage]);

  const handleChooseImageFileButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleRemoveImageFileButtonClick = () => {
    setUnFinalisedImage(null);
    setUnFinalisedImageUrl("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleImageFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUnFinalisedImage(file);
      setUnFinalisedImageUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmitImage = async () => {
    if (unFinalisedImage) {
      // crop the image
      await imageCropperObject.cropImage();
      // upscale or downscale the image to minimum crop length
      await imageCropperObject.resizeImage(
        minCropLength.height, minCropLength.width
      );
      // get the resized image
      const resizedImage = imageCropperObject.getImage();
      // set the new image and imageUrl
      setImage(resizedImage);
      setImageUrl(URL.createObjectURL(resizedImage));
    } else {
      setImage(null);
      setImageUrl("");
    }
    // remove the image cropper window
    removePopUpFunction();
  };

  // crop-handler functions
  const handleMouseDown = (event, dragOperation, isTouchScreenDevice) => {
    event.stopPropagation();
    if (!isDragging) {
      setIsDragging(true);
      setDragOperation(dragOperation);
    }
    if (isTouchScreenDevice){
      document.addEventListener("touchend", handleMouseUp);
      const { clientX, clientY } = event.touches[0];
      touchRef.current.x = clientX;
      touchRef.current.y = clientY;
    } else {
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragOperation("");
    document.removeEventListener("mouseup", handleMouseUp);
    document.removeEventListener("touchend", handleMouseUp);
  };

  const handleMouseMove = (event, isTouchScreenDevice) => {
    // event.preventDefault();
    if (isDragging) {
      const parentRect = cropperContainerRef.current.parentElement.getBoundingClientRect();
      const childRect = cropperContainerRef.current.getBoundingClientRect();
      // calculate the extra offset length
      const imageDimensions = imageCropperObject.getImageDimensionsInDom();
      const imageExtraOffsetHeight = (imageContainerDimensions.height - imageDimensions.height)/2;
      const imageExtraOffsetWidth = (imageContainerDimensions.width - imageDimensions.width)/2;
      // calculate offset values
      const topOffset = childRect.top - (parentRect.top + imageExtraOffsetHeight);
      const rightOffset = childRect.right - (parentRect.right - imageExtraOffsetWidth);
      const bottomOffset = childRect.bottom - (parentRect.bottom - imageExtraOffsetHeight);
      const leftOffset = childRect.left - (parentRect.left + imageExtraOffsetWidth);
      // manipulate crop square frame dimensions and position

      if (isTouchScreenDevice){
        const { clientX, clientY } = event.touches[0];
        const deltaX = clientX - touchRef.current.x;
        const deltaY = clientY - touchRef.current.y;

        touchRef.current.x = clientX;
        touchRef.current.y = clientY;

        event.movementX = deltaX > 0 ? 1 : deltaX < 0 ? -1 : 0;
        event.movementY = deltaY > 0 ? 1 : deltaY < 0 ? -1 : 0;
      }
      imageCropperObject.manipulateCropSquareFrame(
        event.movementX, event.movementY, dragOperation,
        { topOffset, rightOffset, bottomOffset, leftOffset }
      );
      setCropperInfo(imageCropperObject.getCropperInfo());
    }
  };

  return (
    <div
      className="image-crop-input"
      onMouseMove={(event) => handleMouseMove(event, false)}
      onTouchMove={(event) => handleMouseMove(event, true)}
    >
      <div className="image-crop-container">
        <div
          className="display-image"
          style={{ aspectRatio }}
          ref={imageContainerRef}
        >
          <img
            src={unFinalisedImageUrl}
            style={{ backgroundColor: unFinalisedImageUrl ? "white" : null }}
            alt={placeholder ? placeholder : "Select image"}
          />

          <input
            style={{ display: "none" }}
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleImageFileSelect}
          />

          {(unFinalisedImage && cropperInfo.height) ? (
            <div
              className="cropper"
              ref={cropperContainerRef}
              style={{
                aspectRatio,
                height: cropperInfo.height,
                top: cropperInfo.topOffset,
                left: cropperInfo.leftOffset
              }}
              onMouseDown={(event) => handleMouseDown(event, "shift-position", false)}
              onTouchStart={(event) => handleMouseDown(event, "shift-position", true)}
              onMouseMove={(event) => handleMouseMove(event, false)}
              onTouchMove={(event) => handleMouseMove(event, true)}
            >
              <div
                className="dot top-left"
                onMouseDown={(event) => handleMouseDown(event, "resize-top-left", false)}
                onTouchStart={(event) => handleMouseDown(event, "resize-top-left", true)}
              ></div>

              <div
                className="dot top-right"
                onMouseDown={(event) => handleMouseDown(event, "resize-top-right", false)}
                onTouchStart={(event) => handleMouseDown(event, "resize-top-right", true)}
              ></div>

              <div
                className="dot bottom-left"
                onMouseDown={(event) => handleMouseDown(event, "resize-bottom-left", false)}
                onTouchStart={(event) => handleMouseDown(event, "resize-bottom-left", true)}
              ></div>

              <div
                className="dot bottom-right"
                onMouseDown={(event) => handleMouseDown(event, "resize-bottom-right", false)}
                onTouchStart={(event) => handleMouseDown(event, "resize-bottom-right", true)}
              ></div>
            </div>
          ) : (<div></div>)}
        </div>
      </div>

      <div className="controls">
        <div className="choose-image-btn">
          <button onClick={handleChooseImageFileButtonClick}>
            {unFinalisedImage ? "Choose a different image" : "Click to choose an image"}
          </button>

          {isImageDimensionInvalid && (
            <p>*Image dimensions must be greater than or equal to
              {" (" + minCropLength.width} x {minCropLength.height + ")"}</p>
          )}
        </div>

        <div className="safe-exit">
          {unFinalisedImage ? (
            <div className="remove-image-btn"
              onClick={handleRemoveImageFileButtonClick}
            >
              {/*<FontAwesomeIcon icon={faTrashCan} className="icon"/>*/}

              <p>Remove</p>
            </div>
          ) : (<div></div>)}

          <ActionButton
            cancelButtonName={"Cancel"}
            cancelButtonType={"button"}
            cancelButtonOnCLick={removePopUpFunction}
            submitButtonName={"Confirm"}
            submitButtonType={"submit"}
            submitButtonOnCLick={handleSubmitImage}
          />
        </div>
      </div>
    </div>
  );
}

ImageCropInput.propTypes = {
  removePopUpFunction: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  setImage: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  setImageUrl: PropTypes.func.isRequired
};

ImageCropInput.defaultProps = {
  aspectRatio: 1
};