import React from "react";
import PropTypes from "prop-types";
import "../styles/components/FixedPopUp.scss";


export default function FixedPopUp({children}) {
  return (
    <div className="fixed-pop-up">
      {children}
    </div>
  );
}

FixedPopUp.propTypes = {
  children: PropTypes.node.isRequired
};