import React, { createContext } from "react";
import PropTypes from "prop-types";
import { useProvideDashboard } from "../hooks/index.js";


export const DashboardContext = createContext({
  isLoading: true,
  inventoryList: [],
  inventoryId: "",
  inventory: {},
  changeInventory: () => {},
});

export const DashboardProvider = ({ children }) => {
  const dashboard = useProvideDashboard();

  return (
    <DashboardContext.Provider
      value={dashboard}
    >
      {children}
    </DashboardContext.Provider>
  );
};

DashboardProvider.propTypes = {
  children: PropTypes.node.isRequired
};