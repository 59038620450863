import React, { createContext } from "react";
import PropTypes from "prop-types";
import { useProvideAuth } from "../hooks/index.js";


export const AuthContext = createContext({
  user: null,
  inventoryId: "",
  setInventoryId: () => {},
  loading: true,
  changeReCaptchaContainer: () => {},
  removeReCaptchaContainer: () => {},
  setUserAndTokens: () => {},
  handleEmailIdServerVerification: () => {},
  handleCheckEmailIdExists: () => {},
  handleMobileNumberServerVerification: () => {},
  handleCheckMobileNumberExists: () => {},
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider
      value={auth}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};