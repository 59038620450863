import React from "react";
import "../../styles/Home/Carousel.scss";
import mainCarousalImage from "../../assets/home/carousel/main.png";


export default function Carousel() {

  return (
    <div
      className="carousel"
      style={{ backgroundImage: `url(${mainCarousalImage})` }}
    >
    </div>
  );
}
