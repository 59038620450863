import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/components/VentoryLogo.scss";
import ventoryFullLogo from "../assets/shared/full-logo.png";


export default function VentoryLogo() {
  const navigate = useNavigate();

  return (
    <div className="ventory-logo"
      onClick={() => navigate("/")}
    >
      <img src={String(ventoryFullLogo)} alt="ventory logo" />
    </div>
  );
}