import React from "react";
import "../../../styles/Dashboard/Home/index.scss";
import InventoryCount from "./InventoryCount.js";


export default function Home() {
  return (
    <div className="dashboard-home">
      <main>
        <InventoryCount />
      </main>
    </div>
  );
}