import React from "react";
import "../../../styles/Dashboard/CustomerBilling/index.scss";
import customer_billing_coming_soon from "../../../assets/dashboard/customer-billing-cs.svg";


export default function CustomerBilling() {
  return (
    <div className="customer-billing">
      <main>
        <img src={String(customer_billing_coming_soon)} alt=''/>
      </main>
    </div>
  );
}