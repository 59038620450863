import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "../styles/components/App.scss";
import { useAuth } from "../hooks/index.js";
import {
  DashboardProvider, RegisterProvider
} from "../providers/index.js";
import { VentoryLoader } from "../components/index.js";
import Home from "./Home/index.js";
import Register from "./Register/index.js";
import Dashboard from "./Dashboard/index.js";
import LegalAgreements from "./LegalAgreements/index.js";
import PlansAndPricing from "./PlansAndPricing/index.js";


function PrivateRoute({ children, auth }){
  return auth.user ? (
    children
  ): (
    <Navigate to='/' />
  );
}

export default function App() {
  const auth = useAuth();

  if (auth.loading){
    return (
      <div className="app">
        <VentoryLoader height="100px" />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route
          path="/register"
          element={
            <RegisterProvider>
              <Register />
            </RegisterProvider>
          }
        />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute auth={auth}>
              <DashboardProvider>
                <Dashboard />
              </DashboardProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/legal-agreements/*"
          element={<LegalAgreements />}
        />

        <Route
          path="/plans-and-pricing"
          element={<PlansAndPricing />}
        />

        <Route
          path="*"
          element={<Navigate to="/"/>}
        />
      </Routes>
    </BrowserRouter>
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  auth: PropTypes.object.isRequired
};