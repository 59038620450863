import { isString } from "./StringUtils.js";
import {
  setItemInSessionStorage,
  getItemFromSessionStorage
} from "./SessionStorageUtils.js";
import {
  generateKey,
  encryptText,
  decryptText
} from "./CryptographyUtils.js";


export const setItemInLocalStorageCrypt = (key, value) => {
  if (!key) {return;}
  const valueToStore = isString(value) ? value : JSON.stringify(value);

  // encrypt the text and then store in local storage
  let cryptoKey = getItemFromSessionStorage("crypto_key");
  if (!cryptoKey) {
    generateKey().then((cryptoKey) => {
      setItemInSessionStorage("crypto_key", cryptoKey);

      // encrypt key
      encryptText(key, cryptoKey)
        .then((encryptedKey) => {
          // encrypt value
          encryptText(valueToStore, cryptoKey)
            .then((encryptedText) =>
              localStorage.setItem(encryptedKey, encryptedText)
            );
        });
    });
  } else {
    // encrypt key
    encryptText(key, cryptoKey)
      .then((encryptedKey) => {
        // encrypt value
        encryptText(valueToStore, cryptoKey)
          .then((encryptedText) =>
            localStorage.setItem(encryptedKey, encryptedText)
          );
      });
  }
};

export const getItemFromLocalStorageCrypt = async (key) => {
  if (!key) {return null;}

  // get the crypto key from the session storage
  let cryptoKey = getItemFromSessionStorage("crypto_key");
  if (!cryptoKey) {return null;}

  // get the encrypted key
  const encryptedKey = await encryptText(key, cryptoKey);

  if (!encryptedKey) {return null;}

  const item = localStorage.getItem(encryptedKey);

  if (item) {
    return await decryptText(item, cryptoKey);
  } else {return null;}
};

// export const removeItemFromLocalStorageCrypt = (key) => {
//   if (!key) {return;}
//
//   // get the crypto key from the session storage
//   let cryptoKey = getItemFromSessionStorage("crypto_key");
//   if (!cryptoKey) {return;}
//
//   // get the encrypted key
//   encryptText(key, cryptoKey).then((encryptedKey) => {
//     if (!encryptedKey) {return;}
//     localStorage.removeItem(encryptedKey);
//   });
// };

export const clearLocalStorage = () => {
  localStorage.clear();
};