import React from "react";
import PropTypes from "prop-types";
import "../styles/components/Loader.scss";


export default function Loader(props) {
  const { height, color} = props;

  return (
    <div
      className="loader"
      style={{
        height,
        borderTopColor: color
      }}
    ></div>
  );
}

Loader.propTypes = {
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

Loader.defaultProps = {
  height: undefined,
  color: "seagreen"
};