import { useContext, useEffect, useState } from "react";
import { ManageInventoryContext } from "../providers/index.js";
import { useDashboard, useNotification } from "../hooks/index.js";
import { db } from "../configs/index.js";
import {
  addItem as requestAddItem,
  editItem as requestEditItem,
  deleteItem as requestDeleteItem,
  addImage as requestAddImage,
  deleteImage as requestDeleteImage,
  updateItemAvailability as requestUpdateItemAvailability
} from "../api/index.js";
import { isString } from "../utils/index.js";


export const useManageInventory = () => {
  return useContext(ManageInventoryContext);
};

export const useProvideManageInventory = () => {
  const notification = useNotification();
  const dashboard = useDashboard();
  const { inventoryId, inventory } = dashboard;

  const [operationType, setOperationType] = useState("add");
  const [itemCategory, setItemCategory] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemImages, setItemImages] = useState([]);
  const [itemVariants, setItemVariants] = useState([
    { variantName: "default", variantPrice: "" }
  ]);
  const [itemTags, setItemTags] = useState([]);

  // default item values
  const [itemCategoriesList, setItemCategoriesList] = useState([]);
  const [itemPricesList, setItemPricesList] = useState([]);
  const [itemTagsList, setItemTagsList] = useState([]);

  useEffect(() => {
    // get the default_categories list
    db
      .collection("inventory")
      .doc("default_categories")
      .onSnapshot((doc) => {
        if (doc.exists) {setItemCategoriesList(doc.data()["categories_list"]);}
      });

    // get the default_prices list
    db
      .collection("inventory")
      .doc("default_prices")
      .onSnapshot((doc) => {
        if (doc.exists) {setItemPricesList(doc.data()["prices_list"]);}
      });

    // get the default_tags list
    db
      .collection("inventory")
      .doc("default_tags")
      .onSnapshot((doc) => {
        if (doc.exists) {setItemTagsList(doc.data()["tags_list"]);}
      });
  }, [inventory]);

  const resetInput = () => {
    setItemCategory("");
    setItemName("");
    setItemImages([]);
    setItemDescription("");
    setItemVariants([{ variantName: "default", variantPrice: "" }]);
    setItemTags([]);
  };

  const setOperationTypeToAddItem = () => {
    setOperationType("add");
    resetInput();
  };

  const setOperationTypeToEditItem = () => {
    setOperationType("edit");
    resetInput();
  };

  const getSanitizedItemCategory = () => {
    return isString(itemCategory) ? itemCategory.toUpperCase() : "";
  };
  const getSanitizedItemName = () => {
    return isString(itemName) ? itemName : "";
  };
  const getSanitizedItemDescription = () => {
    return isString(itemDescription) ? itemDescription : "";
  };
  const getSanitizedItemVariants = () => {
    // remove all variants where variant-name and variant-price is empty
    return itemVariants.filter(variant => (
      variant.variantName !== "" && variant.variantPrice !== ""
    )).reverse();
  };
  const getSanitizedItemTags = () => {
    return itemTags;
  };

  const uploadNewItem = async () => {
    // notification
    const notificationId = notification.queueNotification(
      `Uploading item '${itemName}'...`
    );
    const newItemUploadResponse = await requestAddItem(
      inventoryId,
      getSanitizedItemCategory(),
      {
        name:  getSanitizedItemName(),
        description: getSanitizedItemDescription(),
        variants: getSanitizedItemVariants(),
        tags: getSanitizedItemTags()
      }
    );
    if (newItemUploadResponse.success){
      // notification (success)
      notification.setNotificationStatusSuccess(
        `Item '${itemName}' is uploaded successfully.`, notificationId
      );
    } else {
      // notification (failed)
      notification.setNotificationStatusFailed(
        `Failed to upload item '${itemName}.'`, notificationId
      );
    }
  };

  const uploadEditedItem = async (category, itemId, item) => {
    // add the notification (loading)
    const notificationId = notification.queueNotification(
      `Editing item '${item.name}'...`
    );

    // API: upload new item request
    const editItemUploadResponse = await requestEditItem(
      inventoryId, category, itemId, item
    );

    if (editItemUploadResponse.success){
      // notification (success)
      notification.setNotificationStatusSuccess(
        `Item '${item.name}' edited successfully.`,
        notificationId
      );
    } else {
      // notification (failed)
      notification.setNotificationStatusFailed(
        `Failed to edit item '${item.name}.'`,
        notificationId
      );
    }

    // reset the add-edit-item window
    setOperationTypeToAddItem();

    return editItemUploadResponse;
  };

  const deleteItem = async (category, itemId) => {
    // add the notification (loading)
    const notificationId = notification.queueNotification("Deleting item...");

    // API: delete item request
    const itemDeleteResponse = await requestDeleteItem(
      inventoryId, category, itemId
    );

    if (itemDeleteResponse.success){
      // update the notification (success)
      notification.setNotificationStatusSuccess(
        "Item successfully deleted.",
        notificationId
      );
    } else {
      // update the notification (failed)
      notification.setNotificationStatusFailed(
        "Failed to delete the item.",
        notificationId
      );
    }

    return itemDeleteResponse;
  };

  const uploadItemImage = async (category, itemId, imageFilePart) => {
    // add the notification (loading)
    const notificationId = notification.queueNotification(
      "Uploading item image..."
    );

    const formData = new FormData();
    formData.append("image", imageFilePart);

    // API: add image request
    const itemImageUploadResponse = await requestAddImage(
      formData, inventoryId, category, itemId
    );

    if (itemImageUploadResponse.success) {
      // update the notification (success)
      notification.setNotificationStatusSuccess(
        "Item image successfully uploaded.",
        notificationId
      );
    } else {
      // update the notification (failed)
      notification.setNotificationStatusFailed(
        "Failed to upload item image.",
        notificationId
      );
    }

    return itemImageUploadResponse;
  };

  const deleteItemImage = async (category, itemId) => {
    // add the notification (loading)
    const notificationId = notification.queueNotification(
      "Deleting item image..."
    );

    // API: delete image request
    const deleteItemImageResponse = await requestDeleteImage(
      inventoryId, category, itemId
    );

    if (deleteItemImageResponse.success) {
      // update the notification (success)
      notification.setNotificationStatusSuccess(
        "Item image is successfully deleted.",
        notificationId
      );
    } else {
      // update the notification (failed)
      notification.setNotificationStatusFailed(
        "Failed to delete item image.",
        notificationId
      );
    }

    // reset the add-edit-item window
    setOperationTypeToAddItem();

    return deleteItemImageResponse;
  };

  const setItemAvailability = async (category, itemId, availability) => {
    // API: update-item-availability request
    return await requestUpdateItemAvailability(
      inventoryId, category, itemId, availability
    );
  };

  return {
    itemCategory,
    setItemCategory,
    itemName,
    setItemName,
    itemDescription,
    setItemDescription,
    itemImages,
    setItemImages,
    itemVariants,
    setItemVariants,
    itemTags,
    setItemTags,
    operationType,
    getSanitizedItemCategory,
    getSanitizedItemName,
    getSanitizedItemDescription,
    getSanitizedItemVariants,
    getSanitizedItemTags,
    resetInput,
    setOperationTypeToAddItem,
    setOperationTypeToEditItem,
    uploadNewItem,
    uploadEditedItem,
    uploadItemImage,
    deleteItemImage,
    deleteItem,
    setItemAvailability,
    itemCategoriesList,
    itemPricesList,
    itemTagsList
  };
};
