import React from "react";
import PropTypes from "prop-types";
import "../styles/components/ActionButton.scss";


export default function ActionButton(props) {
  const {
    cancelButtonName, cancelButtonType, cancelButtonOnCLick,
    submitButtonName, submitButtonType, submitButtonOnCLick
  } = props;

  return (
    <div className="action-button">
      <button
        className="cancel-btn"
        type={cancelButtonType}
        onClick={cancelButtonOnCLick}
      >
        {cancelButtonName}
      </button>

      <button
        className="submit-btn"
        type={submitButtonType}
        onClick={submitButtonOnCLick}
      >
        {submitButtonName}
      </button>
    </div>
  );
}

ActionButton.propTypes = {
  cancelButtonName: PropTypes.string.isRequired,
  cancelButtonType: PropTypes.string,
  cancelButtonOnCLick: PropTypes.func.isRequired,
  submitButtonName: PropTypes.string.isRequired,
  submitButtonType: PropTypes.string,
  submitButtonOnCLick: PropTypes.func.isRequired
};

ActionButton.defaultProps = {
  cancelButtonType: "button",
  submitButtonType: "submit"
};