import React from "react";
import "../../styles/Register/InputContactDetails.scss";
import { CredVerifier, CustomInput } from "../../components/index.js";


export default function InputContactDetails() {
  const handleContactFormSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div className="input-contact-details">
      <form
        className="input-contact-form"
        onClick={handleContactFormSubmit}
      >
        <div className="left-container">
          <CustomInput
            type="text"
            value={""}
            onChange={() => {}}
            placeholder="Contact Name"
            maxLength={30}
            icon="faUser"
            style={{
              height:"70px",
              width:"365px"
            }}
            color="dodgerblue"
            requiredTextColor="gray"
            required
          />
        </div>

        <div className="right-container">
          <div className="curve-line"></div>

          <CredVerifier />
        </div>
      </form>

      <div className="added-contacts">

      </div>
    </div>
  );
}