import React from "react";
import PropTypes from "prop-types";
import "../styles/components/PopUp.scss";


export default function PopUp({ children }) {
  return (
    <div className="pop-up">
      {children}
    </div>
  );
}

PopUp.propTypes = {
  children: PropTypes.node.isRequired
};