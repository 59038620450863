import React, { useState } from "react";
import PropTypes from "prop-types";
import "../styles/components/CustomDropdown.scss";


export default function CustomDropdown(props) {
  const { dropdownList, value, onSelect, placeholder, icon } = props;

  const [isDropdownFocused, setIsDropdownFocused] = useState(false);

  return (
    <div className="custom-dropdown">
      <div className="label-container">
        <p
          className="label"
          style={{
            animation: value ? "labelUp forwards" : isDropdownFocused
              ? "labelUp 0.2s ease-in-out forwards" : "",
            color: isDropdownFocused ? "currentcolor" : ""
          }}
        >
          {placeholder}
        </p>
      </div>

      <div
        className="custom-dropdown-container"
        style={{ borderColor: isDropdownFocused ? "currentcolor" : "" }}
        tabIndex="0"
        onFocus={() => setIsDropdownFocused(true)}
        onBlur={() => setIsDropdownFocused(false)}
      >
        <div className="icon-container">
          {/*<FontAwesomeIcon*/}
          {/*  className="icon"*/}
          {/*  icon={icon}*/}
          {/*  style={{ color: isDropdownFocused ? "currentcolor" : "" }}*/}
          {/*/>*/}
        </div>

        <div className="select-category">
          <p>{value}</p>
        </div>

        <div className="icon-container expand">
          {/*<FontAwesomeIcon*/}
          {/*  className="icon"*/}
          {/*  icon={faCaretDown}*/}
          {/*  style={{ color: isDropdownFocused ? "currentcolor" : "" }}*/}
          {/*/>*/}
        </div>
      </div>

      {isDropdownFocused && (
        <div className="custom-dropdown-list">
          {dropdownList.map((dropdownItem, index) => (
            <button
              key={`dropdown-item-${index}`}
              onMouseDown={() => onSelect(dropdownItem)}
            >
              {dropdownItem}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

CustomDropdown.propTypes = {
  dropdownList: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};