import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../../../styles/Dashboard/ManageInventory/AddEditItem/SelectVariantPricePanel.scss";
import { ActionButton } from "../../../../components/index.js";
import { validateNumber } from "../../../../utils/index.js";
import { useManageInventory } from "../../../../hooks/index.js";


export default function SelectVariantPricePanel(props) {
  const { removePopUpFunction, price, setPrice } = props;
  const manageInventory = useManageInventory();
  const { itemPricesList } = manageInventory;

  const [priceInput, setPriceInput] = useState(
    price.includes("₹") ? parseInt(price.substring(2)) : ""
  );

  const handlePriceSubmit = (event) => {
    event.preventDefault();
    setPrice(parseInt(priceInput) ? `₹ ${parseInt(priceInput)}` : "FREE");
  };

  const handlePriceInputChange = (event) =>
    setPriceInput(validateNumber(event.target.value));

  return (
    <div className="select-variant-price-panel">
      <form
        className="set-price-form"
        onSubmit={handlePriceSubmit}
      >
        <div className="price-input-container">
          <p>₹</p>

          <input
            className="price-input"
            type="text"
            placeholder="000"
            value={priceInput}
            onChange={handlePriceInputChange}
            maxLength={13}
            required
          />
        </div>

        <div className="safe-exit">
          <ActionButton
            cancelButtonName={"Cancel"}
            cancelButtonType={"button"}
            cancelButtonOnCLick={removePopUpFunction}
            submitButtonName={"Set"}
            submitButtonType={"submit"}
            submitButtonOnCLick={() => {}}
          />
        </div>
      </form>

      <div className="choose-price-container">
        {itemPricesList.map((price, index) => (
          <button
            key={`price-${index}`}
            className="price-btn"
            onClick={() => setPrice(price)}
          >{price}</button>
        ))}
      </div>
    </div>
  );
}

SelectVariantPricePanel.propTypes = {
  removePopUpFunction: PropTypes.func.isRequired,
  price: PropTypes.string.isRequired,
  setPrice: PropTypes.func.isRequired
};