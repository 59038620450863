import React from "react";
import "../../styles/Home/index.scss";
import Carousel from "./Carousel.js";
import WhyVentory from "./WhyVentory.js";
import WhyChooseUs from "./WhyChooseUs.js";
import PartnersApp from "./PartnersApp.js";
import CustomerApp from "./CustomerApp.js";
import WhatElse from "./WhatElse.js";
import {
  MaxWidthContainer,
  NavBar,
  Footer
} from "../../components/index.js";


export default function Home() {
  return (
    <div className="home">
      <div className="nav-bar-container">
        <MaxWidthContainer>
          <NavBar profile signIn />
        </MaxWidthContainer>
      </div>

      <div className="carousel-container">
        <MaxWidthContainer>
          <Carousel />
        </MaxWidthContainer>
      </div>

      <div className="section-container">
        <MaxWidthContainer>
          <WhyVentory />
        </MaxWidthContainer>
      </div>

      <div className="section-container">
        <MaxWidthContainer>
          <WhyChooseUs />
        </MaxWidthContainer>
      </div>

      <div className="section-container">
        <MaxWidthContainer>
          <PartnersApp />
        </MaxWidthContainer>
      </div>

      <div className="section-container">
        <MaxWidthContainer>
          <CustomerApp />
        </MaxWidthContainer>
      </div>

      <div className="section-container">
        <MaxWidthContainer>
          <WhatElse />
        </MaxWidthContainer>
      </div>

      <div className="footer-container">
        <MaxWidthContainer>
          <Footer />
        </MaxWidthContainer>
      </div>
    </div>
  );
}