import React, { createContext } from "react";
import PropTypes from "prop-types";
import { useProvideManageInventory } from "../hooks/index.js";


export const ManageInventoryContext = createContext({
  itemCategory: "",
  setItemCategory: () => {},
  itemName: "",
  setItemName: () => {},
  itemDescription: "",
  setItemDescription: () => {},
  itemImages: [],
  setItemImages: () => {},
  itemVariants: [],
  setItemVariants: () => {},
  itemTags: [],
  setItemTags: () => {},
  operationType: "",
  getSanitizedItemCategory: () => {},
  getSanitizedItemName: () => {},
  getSanitizedItemDescription: () => {},
  getSanitizedItemVariants: () => {},
  getSanitizedItemTags: () => {},
  resetInput: () => {},
  setOperationTypeToAddItem: () => {},
  setOperationTypeToEditItem: () => {},
  uploadNewItem: () => {},
  uploadEditedItem: () => {},
  deleteItem: () => {},
  uploadItemImage: () => {},
  deleteItemImage: () => {},
  setItemAvailability: () => {},
  itemCategoriesList: [],
  itemPricesList: [],
  itemTagsList: []
});


export const ManageInventoryProvider = ({ children }) => {
  const manageInventory = useProvideManageInventory();

  return (
    <ManageInventoryContext.Provider
      value={manageInventory}
    >
      {children}
    </ManageInventoryContext.Provider>
  );
};

ManageInventoryProvider.propTypes = {
  children: PropTypes.node.isRequired
};