import { HOST_IP } from "../configs/index.js";
import { request } from "./request.js";

const ENDPOINTS = {
  authenticate: `${HOST_IP}/auth/authenticate`,
  changeInventory: `${HOST_IP}/auth/change-inventory`,
  emailAuthVerifyOtp: `${HOST_IP}/auth/email-auth-verify-otp`,
  emailAuthSendOtp: `${HOST_IP}/auth/email-auth-send-otp`,
  checkEmail: (email) =>
    (`${HOST_IP}/auth/check-email?email=${encodeURIComponent(email)}`),
  checkMobile: (mobile) =>
    (`${HOST_IP}/auth/check-mobile?mobile=${encodeURIComponent(mobile)}`)
};

export const authenticate = (refresh_token) => {
  return request(
    ENDPOINTS.authenticate,
    {
      method: "POST",
      headers: { Authorization: refresh_token }
    },
    false // enableJWT
  );
};

export const emailAuthSendOtp = (email) => {
  return request(
    ENDPOINTS.emailAuthSendOtp,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email })
    },
    false // enableJWT
  );
};

export const emailAuthVerifyOtp = (otp, session_token) => {
  return request(
    ENDPOINTS.emailAuthVerifyOtp,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ otp, session_token })
    },
    false // enableJWT
  );
};

export const changeInventory = (elevated_access_token, inventoryId) => {
  return request(
    ENDPOINTS.changeInventory,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ elevated_access_token, inventoryId })
    },
    false // enableJWT
  );
};

export const checkEmail = (email) => {
  return request(
    ENDPOINTS.checkEmail(email),
    { method: "GET" },
    false // enableJWT
  );
};

export const checkMobile = (mobile) => {
  return request(
    ENDPOINTS.checkMobile(mobile),
    { method: "GET" },
    false // enableJWT
  );
};
