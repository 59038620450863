import { isString } from "./StringUtils.js";

export const setItemInSessionStorage = (key, value) => {
  if (!key) {return null;}
  const valueToStore = isString(value) ? value : JSON.stringify(value);
  sessionStorage.setItem(key, valueToStore);
};

export const getItemFromSessionStorage = (key) => {
  if (!key) {return null;}
  return sessionStorage.getItem(key);
};

// export const removeItemFromSessionStorage = (key) => {
//   if (!key) {return null;}
//   sessionStorage.removeItem(key);
// };
//
// export const clearSessionStorage = () => {
//   sessionStorage.clear();
// };