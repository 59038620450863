import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import "../../styles/components/NavBar/NavBar.scss";
import VentoryLogo from "../VentoryLogo.js";
import { useAuth } from "../../hooks/index.js";
import { MaxWidthContainer } from "../index.js";


export default function NavBar(props) {
  const { profile, signIn } = props;
  const navigate = useNavigate();
  const auth = useAuth();

  const [profileButtonClicked, setProfileButtonClicked] = useState(false);

  const handleToggleProfileButton = () => {
    if (profile) {setProfileButtonClicked(!profileButtonClicked);}
  };

  useEffect(() => {
    if (!auth.user) {setProfileButtonClicked(false);}
  }, [auth]);

  return (
    <nav className="nav-bar">
      <VentoryLogo />

      <div className="nav-bar-right">
        {(profile && auth.user) && (
          <div
            className="profile-icon"
            onClick={handleToggleProfileButton}
          >
            <i className="fa-solid fa-shop icon"></i>
          </div>
        )}

        {(signIn && !auth.user) && (
          <button
            className="sign-in-btn"
            onClick={() => navigate("/register")}
          >
            <p>SIGN IN</p>
            <i className="fa-solid fa-user-tie icon"></i>
          </button>
        )}
      </div>

      {(profileButtonClicked) && (
        <MaxWidthContainer>
          <div className="profile-tab">
            <button className="logout-btn" onClick={auth.logout}>
              <p>Logout</p>
              <i className="fa-solid fa-power-off icon"></i>
            </button>
          </div>
        </MaxWidthContainer>
      )}
    </nav>
  );
}

NavBar.propTypes = {
  profile: PropTypes.bool.isRequired,
  signIn: PropTypes.bool.isRequired
};

NavBar.defaultProps = {
  profile: undefined,
  signIn: undefined
};