import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getAuth } from "firebase/auth";
import firebaseConfig from "./firebase_config.json";

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = getAuth(app);

export const FIREBASE_STORAGE_BUCKET_URL = `https://storage.googleapis.com/${firebaseConfig.storageBucket}`;