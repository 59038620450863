import React from "react";
import "../../../styles/Dashboard/Home/InventoryCount.scss";
// import { useDashboard } from "../../../hooks/index.js";


export default function InventoryCount() {
  // const dashboard = useDashboard();
  // const categoriesCount = inventoryProvider.Inventory.order.length;

  return (
    <div className="inventory-count">
      {/*<h2>Total Items: {categoriesCount}</h2>*/}
    </div>
  );
}