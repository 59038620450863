import React from "react";
import "../styles/components/Notification.scss";
import { useNotification } from "../hooks/index.js";
import Loader from "./Loader.js";


export default function Notification() {
  const notificationProvider = useNotification();
  const notificationQueue = notificationProvider.notificationQueue;

  return (
    <div className="notifications">
      {notificationQueue.map((notification, index) => (notification && (
        <div
          key={`notification-${index}`}
          className="notification"
          style={(notification.type === "success" ||
                  notification.type === "failed")
            ? {
              animation: "contract 1s ease-in-out forwards",
              animationDelay: "3s"
            } : {}
          }
        >
          <div className="status-icon">
            {notification.type === "loading" &&
              <Loader height="60%" color="seagreen" />
            }
            {notification.type === "success" && (
              <div></div>
              // <FontAwesomeIcon icon={faFaceLaughWink} className="icon success"/>
            )}
            {notification.type === "failed" && (
              <div></div>
              // <FontAwesomeIcon icon={faFaceFrown} className="icon failed" />
            )}
          </div>

          <div className="status-text">
            <div className="text">
              <p>{notification.message}</p>
            </div>

            <div
              className="remove-icon"
              onClick={() => notificationProvider.removeNotification(index)}
            >
              {/*<FontAwesomeIcon icon={faXmark} className="icon" />*/}
            </div>
          </div>
        </div>
      )))}
    </div>
  );
}