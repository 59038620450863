import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import LegalAgreement from "./LegalAgreement.js";


export default function LegalAgreements() {

  return (
    <Routes>
      <Route
        index
        element={<Navigate to="../"/>}
      />

      <Route
        path="terms-and-conditions"
        element={<LegalAgreement agreementType="terms-and-conditions" />}
      />

      <Route
        path="privacy-and-policy"
        element={<LegalAgreement agreementType="privacy-and-policy" />}
      />

      <Route
        path="return-and-refund-policy"
        element={<LegalAgreement agreementType="return-and-refund-policy" />}
      />

      <Route
        path="*"
        element={<Navigate to="../"/>}
      />
    </Routes>
  );
}