import React from "react";
import "../../styles/Home/WhyChooseUs.scss";


export default function WhyChooseUs() {
  return (
    <div className="why-choose-us">
      <div className='why-choose-us-top'>
        <p className="title">Why Choose Us</p>

        <p className='heading'>
          Empowering Your Success, One Decision at a Time
        </p>
      </div>

      <div className='why-choose-us-bottom'>
        <div className='container'>
          <div className='heading-container'>
            <div className="heading_">
              <p> Modern Solution </p>

              <i className="fa-solid fa-stars"></i>
            </div>
          </div>

          <div className='description-container'>
            <p className="description">
              Ventory is packed with powerful features at an affordable
              price—from complete inventory control to stunning visual
              displays that help market your shop effortlessly, all with
              just a single click.
            </p>
          </div>
        </div>

        <div className='container'>
          <div className='heading-container'>
            <div className="heading_">
              <p> Absolute Control </p>

              <i className="fa-solid fa-tower-control"></i>
            </div>
          </div>

          <div className='description-container'>
            <p className="description">
              In Ventory, you can easily assign each staff member only the
              specific permissions they need for their role. This ensures
              every employee accesses only what’s necessary for their tasks
              and responsibilities.
            </p>
          </div>
        </div>

        <div className='container'>
          <div className='heading-container'>
            <div className="heading_">
              <p> Artificial Intelligence </p>

              <i className="fa-regular fa-microchip-ai"></i>
            </div>
          </div>

          <div className='description-container'>
            <p className="description">
              Ventory’s smart AI shows customers the best product choices,
              helping them decide what to buy. It keeps learning from customer
              reviews, making shopping easier and more personalized over time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}