import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "../styles/components/CredVerifier.scss";
import Loader from "./Loader.js";
import { useAuth, useNotification } from "../hooks/index.js";
import {
  validateNumber,
  validateEmailId,
  validateMobileNumber,
  sendOtpToEmailId,
  verifyEmailIdOtp,
  sendOtpToMobileNumber,
  verifyMobileNumberOtp
} from "../utils/index.js";


export default function CredVerifier(props) {
  const {
    defaultCredType,
    verifyEmailIdOnClick,
    onEmailIdTokenChange,
    verifyMobileNumberOnClick,
    onMobileNumberTokenChange
  } = props;

  const [selectedCredType, setSelectedCredType] = useState(defaultCredType);
  const [emailId, setEmailId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [OTP, setOTP] = useState(["", "", "", "", "", ""]);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isVerifyButtonClicked, setIsVerifyButtonClicked] = useState(false);
  const [sessionToken, setSessionToken] = useState(null);
  const inputRef = useRef(null);
  const OTPInputRefs = useRef([]);
  const auth = useAuth();
  const notification = useNotification();

  useEffect(() => {
    resetStates();
  }, []);

  useEffect(() => {
    if (!isOTPSent) {
      auth.removeReCaptchaContainer();
    }
  }, [isOTPSent]);

  const resetStates = () => {
    setIsVerifyButtonClicked(false);
    setIsOTPSent(false);
    setEmailId("");
    setMobileNumber("");
    setOTP(["", "", "", "", "", ""]);
  };

  const handleSelectCredTypeOnClick = (selectedCredType) => {
    resetStates();
    setSelectedCredType(selectedCredType);
    inputRef.current.focus();
  };

  const handleInputValueChange = (event) => {
    setIsVerifyButtonClicked(false);
    setIsOTPSent(false);
    setOTP(["", "", "", "", "", ""]);
    if (selectedCredType === "email") {
      setEmailId(event.target.value);
    }
    if (selectedCredType === "mobile") {
      setMobileNumber(validateNumber(event.target.value));
    }
  };

  const handleOTPChange = (event, index) => {
    const otpDigit = validateNumber(event.target.value);
    if ((otpDigit >= 0 && otpDigit <= 9) || otpDigit === ""){
      let newOTP = [...OTP];
      newOTP[index] = otpDigit;
      setOTP(newOTP);

      if (otpDigit && index < 5) {
        OTPInputRefs.current[index + 1].focus();
      }
      if (!otpDigit && index > 0) {
        OTPInputRefs.current[index - 1].focus();
      }
    }
  };

  const handleOTPInputBackSpace = (event, index) => {
    if ((event.keyCode === 8 || event.keyCode === 46)
      && !event.target.value && index > 0) {
      OTPInputRefs.current[index - 1].focus();
    }
    if (!(event.keyCode === 8) && !(event.keyCode === 46)
      && event.target.value && index < 5) {
      OTPInputRefs.current[index + 1].focus();
    }
  };

  const handleOTPPaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text/plain").slice(0, 6);
    let newOTP = [...OTP];
    newOTP = newOTP.map((digit, index) => {
      return validateNumber(pastedData[index] + "");
    });
    setOTP(newOTP);
  };

  useEffect(() => {
    if (isOTPSent) {OTPInputRefs.current[0].focus();}
  }, [isOTPSent]);

  const handleVerifyButtonClick = async () => {
    let otpSendResponse = { success: false };

    // handle mobile otp send
    if (selectedCredType === "mobile") {
      if (validateMobileNumber(mobileNumber)) {
        setIsVerifyButtonClicked(true);
        if (verifyMobileNumberOnClick) {
          const response = await verifyMobileNumberOnClick(mobileNumber);
          if (!response.success) {
            setIsVerifyButtonClicked(false);
            return;
          }
        }
        otpSendResponse = await sendOtpToMobileNumber(
          mobileNumber, auth.changeReCaptchaContainer());
      }
    }

    // handle email otp send
    if (selectedCredType === "email") {
      if (emailId && validateEmailId(emailId)) {
        setIsVerifyButtonClicked(true);
        if (verifyEmailIdOnClick) {
          const response = await verifyEmailIdOnClick(emailId);
          if (!response.success) {
            setIsVerifyButtonClicked(false);
            return;
          }
        }
        otpSendResponse = await sendOtpToEmailId(emailId);
        if (otpSendResponse.success) {
          setSessionToken(otpSendResponse.data.session_token);
        }
      }
    }

    setIsOTPSent(otpSendResponse.success);
    setIsVerifyButtonClicked(otpSendResponse.success);
  };

  // verify OTP
  useEffect(() => {
    const otp = OTP.join("");
    if (otp.length === 6) {
      const notificationId = notification.queueNotification("Verifying OTP...");

      // handle mobile otp verification
      if (selectedCredType === "mobile") {
        verifyMobileNumberOtp(otp).then((mobileOtpAuthResponse) => {
          if (mobileOtpAuthResponse.success) {
            notification.removeNotification(notificationId);
            resetStates();
            if (onMobileNumberTokenChange) {
              onMobileNumberTokenChange(
                mobileOtpAuthResponse.elevated_access_token
              );
            }
          } else {
            notification.setNotificationStatusFailed(
              "Invalid OTP!", notificationId
            );
          }
        });
      }

      // handle email otp verification
      if (selectedCredType === "email") {
        verifyEmailIdOtp(otp, sessionToken).then((emailOtpAuthResponse) => {
          if (emailOtpAuthResponse.success) {
            notification.removeNotification(notificationId);
            resetStates();
            if (onEmailIdTokenChange) {
              onEmailIdTokenChange(emailOtpAuthResponse.elevated_access_token);
            }
          } else {
            notification.setNotificationStatusFailed(
              "Invalid OTP!", notificationId
            );
          }
        });
      }
    }
  }, [OTP]);

  return (
    <div className="cred-verifier">
      <div className="cred-container">
        {/*select an input type: email or mobile, button*/}
        <div className="signup-option-btn">
          <button
            onClick={() => handleSelectCredTypeOnClick("email")}
            style={{
              backgroundColor: selectedCredType === "email"
                ? "dodgerblue" : "whitesmoke"
            }}
            disabled={selectedCredType === "email"}
          >
            <p style={{ color: selectedCredType === "email" ? "white" : "" }}>
              EMAIL
            </p>
          </button>

          <button onClick={() => handleSelectCredTypeOnClick("mobile")}
            style={{
              backgroundColor: selectedCredType === "mobile"
                ? "dodgerblue" : "whitesmoke"
            }}
            disabled={selectedCredType === "mobile"}
          >
            <p style={{ color: selectedCredType === "mobile" ? "white" : "" }}>
              MOBILE
            </p>
          </button>
        </div>

        <div className="email-mobile-input">
          {/*email, mobile input*/}
          <div className="cred-input">
            {selectedCredType === "mobile" && (<p>+91</p>)}
            <input
              ref={inputRef}
              type={selectedCredType === "email" ? "email" : "text"}
              value={selectedCredType === "email" ? emailId : mobileNumber}
              onChange={handleInputValueChange}
              placeholder={selectedCredType === "email"
                ? "Enter Email Id" : "Enter Mobile Number"
              }
              maxLength={selectedCredType === "mobile" ? 10 : undefined}
              required
            />
          </div>

          {/*verify-button (send otp button)*/}
          <button
            className="verify-btn"
            onClick={handleVerifyButtonClick}
            type="submit"
            disabled={isVerifyButtonClicked}
          >
            {isVerifyButtonClicked
              ? isOTPSent
                ? (<p>OTP SENT</p>)
                : (<Loader height="60%" color="white" />)
              : (<p>VERIFY</p>)}
          </button>
        </div>
      </div>

      {/*otp-input*/}
      {(isVerifyButtonClicked && isOTPSent) && (
        <div className="otp-container">
          <p>
            A 6 digits OTP has been sent to your
            {selectedCredType === "email" ? " email id " : " mobile number "}
            successfully.
          </p>

          <div className="otp-input-boxes">
            {OTP.map((digit, index) => (
              <input
                key={`otp-input-${index}`}
                ref={(element) => (OTPInputRefs.current[index] = element)}
                type="number"
                value={digit}
                onChange={(event) => handleOTPChange(event, index)}
                onPaste={handleOTPPaste}
                onKeyDown={(event) => handleOTPInputBackSpace(event, index)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

CredVerifier.propTypes = {
  defaultCredType: PropTypes.string,
  verifyEmailIdOnClick: PropTypes.func,
  onEmailIdTokenChange: PropTypes.func,
  verifyMobileNumberOnClick: PropTypes.func,
  onMobileNumberTokenChange: PropTypes.func,
};

CredVerifier.defaultProps = {
  defaultCredType: "email",
  verifyEmailIdOnClick: () => {},
  onEmailIdTokenChange: () => {},
  verifyMobileNumberOnClick: () => {},
  onMobileNumberTokenChange: () => {},
};