import React, { useEffect, useState } from "react";
import "../../../../styles/Dashboard/ManageInventory/PreviewInventory/index.scss";
import PreviewItem from "./PreviewItem.js";
import { useDashboard } from "../../../../hooks/index.js";
import { PopUp } from "../../../../components/index.js";
import DeleteItem from "./DeleteItem.js";
import { searchInInventory } from "../../../../utils/index.js";
import default_image from "../../../../assets/dashboard/default.svg";


export default function PreviewInventory() {
  const dashboard = useDashboard();
  const [searchInput, setSearchInput] = useState("");
  const [itemToDelete, setItemToDelete] = useState({});
  const [inventory, setInventory] = useState(dashboard.inventory);

  useEffect(() => {
    if (!searchInput.length) {
      setInventory(dashboard.inventory);
    } else {
      setInventory(searchInInventory(dashboard.inventory, searchInput));
    }
  }, [dashboard.inventory]);

  const handleSearchInput = (event) => {
    const searchString = event.target.value;
    setSearchInput(searchString);

    if (!searchString.length) {
      // if search string is empty, then display the complete Inventory
      setInventory(dashboard.inventory);
    } else {
      // search the item into the Inventory
      setInventory(searchInInventory(dashboard.inventory, searchString));
    }
  };

  const clearItemToDelete = () => {
    setItemToDelete({});
  };

  return (
    <div className="preview-inventory">
      {/*Heading*/}
      <h1 className="heading">Preview</h1>

      {dashboard.inventory.order.length ? (
        <>
          <div className="preview-search">
            <input
              type="text"
              placeholder="Search Item, Category"
              value={searchInput}
              onChange={handleSearchInput}
            />
          </div>

          <div className="preview-items">
            {inventory.order.map((categoryId, categoryIndex) => {
              return ([
                (<div
                  key={`pr-item-${categoryIndex}`}
                  className="pr-item-category"
                >
                  <p>{(
                    inventory[categoryId]["categoryName"].charAt(0).toUpperCase() +
                    inventory[categoryId]["categoryName"].slice(1).toLowerCase())}
                  </p>
                </div>
                ),
                inventory[categoryId].items["order"].map((itemId, itemIndex) => (
                  <PreviewItem
                    key={`pr-item-${categoryIndex}${itemIndex}`}
                    item={inventory[categoryId].items[itemId]}
                    itemId={itemId}
                    category={inventory[categoryId]["categoryName"]}
                    setItemToDelete={setItemToDelete}
                  />
                ))
              ]);
            })}
          </div>

          {(Object.keys(itemToDelete).length !== 0) && (
            <PopUp>
              <DeleteItem
                itemToDelete={itemToDelete}
                removePopUpFunction={clearItemToDelete}
              />
            </PopUp>
          )}
        </>
      ) : (
        <img src={String(default_image)} alt=''/>
      )}
    </div>
  );
}
