import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../../../../styles/Dashboard/ManageInventory/AddEditItem/UploadItemPanel.scss";
import { useManageInventory } from "../../../../hooks/index.js";
import { ActionButton } from "../../../../components/index.js";
import PreviewVariants from "../PreviewInventory/PreviewVariants.js";
import PreviewDescription from "../PreviewInventory/PreviewDescription.js";
import defaultItemImage from "../../../../assets/dashboard/default-item-image.svg";


export default function UploadItemPanel(props) {
  const { removePopUpFunction } = props;
  const manageInventory = useManageInventory();
  const {
    getSanitizedItemCategory, getSanitizedItemName,
    getSanitizedItemDescription, getSanitizedItemVariants,
    getSanitizedItemTags, uploadNewItem, operationType
  } = manageInventory;
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [variants, setVariants] = useState([]);
  const [, setTags] = useState([]);
  // const [tags, setTags] = useState([]);


  useEffect(() => {
    const category = getSanitizedItemCategory();
    const name = getSanitizedItemName();
    const description = getSanitizedItemDescription();
    const variants = getSanitizedItemVariants();
    const tags = getSanitizedItemTags();
    if (!category || !name || !variants.length) {
      removePopUpFunction();
    } else {
      setCategory(category);
      setName(name);
      setDescription(description);
      setVariants(variants);
      setTags(tags);
    }
  }, []);

  const handleUploadItemButtonClick = () => {
    uploadNewItem();
    removePopUpFunction();
  };

  return (
    <div className="upload-item-panel">
      <div className="item-preview-container">
        <div className="item-preview-top-container">
          {/*<FontAwesomeIcon className="icon" icon={faCaretRight} />*/}

          <p>{category}</p>
        </div>

        <div className="item-preview-bottom-container">
          <div className="item-image-cont">
            <img src={String(defaultItemImage)} alt="Preview Image" />
          </div>

          <div className="item-data-cont">
            <h1>{name}</h1>

            <div className="pri-var-preview">
              <PreviewVariants variants={variants} />
            </div>

            <div className="desc-preview">
              <PreviewDescription description={description} />
            </div>
          </div>
        </div>
      </div>

      <div className="safe-exit">
        <ActionButton
          cancelButtonName={"Keep Editing"}
          cancelButtonType={"button"}
          cancelButtonOnCLick={removePopUpFunction}
          submitButtonName={operationType === "add" ? "Add Item" : "Edit Item"}
          submitButtonType={"submit"}
          submitButtonOnCLick={handleUploadItemButtonClick}
        />
      </div>
    </div>
  );
}

UploadItemPanel.propTypes = {
  removePopUpFunction: PropTypes.func.isRequired
};