import React from "react";
import PropTypes from "prop-types";
import "../../../styles/Register/InputGSTDetails/Input.scss";


export default function Input(props) {
  const {value, onChange} = props;

  return (
    <div className="register-gst-details-input">
      <input
        className='input-filled'
        size={1}
        maxLength={1}
        type='text'
        value={value}
        onChange={onChange}
        required
      />

      {(!value) && (
        <div className='input-empty'>
          <i className="icon fa-regular fa-i-cursor"></i>
        </div>
      )}
    </div>
  );
}

Input.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};