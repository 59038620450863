import React from "react";
import "../../../styles/Dashboard/ManageOrders/index.scss";
import manage_orders_coming_soon from "../../../assets/dashboard/manage-orders-cs.svg";


export default function ManageOrders() {
  return (
    <div className="manage-orders">
      <main>
        <img src={String(manage_orders_coming_soon)} alt=''/>
      </main>
    </div>
  );
}