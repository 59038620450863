import React, { createContext } from "react";
import PropTypes from "prop-types";
import { useProvideNotification } from "../hooks/index.js";
import { Notification } from "../components/index.js";


export const NotificationContext = createContext({
  notificationQueue: [],
  queueNotification: () => {},
  removeNotification: () => {},
  setNotificationStatusSuccess: () => {},
  setNotificationStatusFailed: () => {}
});

export const NotificationProvider = ({ children }) => {
  const notification = useProvideNotification();

  return (
    <NotificationContext.Provider
      value={notification}
    >
      {children}
      <Notification />
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired
};