import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../../../styles/Dashboard/ManageInventory/PreviewInventory/PreviewItem.scss";
import { FIREBASE_STORAGE_BUCKET_URL } from "../../../../configs/index.js";
import { useDashboard } from "../../../../hooks/index.js";
import { Loader } from "../../../../components/index.js";
import PreviewVariants from "./PreviewVariants.js";
import PreviewDescription from "./PreviewDescription.js";
import defaultItemImage from "../../../../assets/dashboard/default-item-image.svg";


export default function PreviewItem(props) {
  const { itemId, item, category, setItemToDelete } = props;

  const dashboard = useDashboard();
  const inventoryId = dashboard.inventoryId;

  const imageUrl = item["imageId"]
    ? `${FIREBASE_STORAGE_BUCKET_URL}/${inventoryId}/${item["imageId"]}`
    : "";

  // toggle item availability
  const [
    availabilityUpdateStatus, setAvailabilityUpdateStatus
  ] = useState(false);
  const handleToggleItemAvailability = async () => {
    setAvailabilityUpdateStatus(true);

    // request toggle the item availability
    await dashboard.setItemAvailability(
      category, itemId, !item.availability
    );

    setAvailabilityUpdateStatus(false);
  };

  const handleEditItemButtonClick = () => {
    dashboard.setOperationTypeToEditItem({
      itemId,
      category,
      ...item,
      imageUrl,
      variants: item.variants.slice().reverse()
    });
  };

  const handleItemDeletion = () => {
    setItemToDelete({
      category,
      itemId,
      name: item.name
    });
  };

  return (
    <div className="preview-item">
      <div className="pr-item-image">
        <img
          src={imageUrl ? imageUrl : String(defaultItemImage)}
          alt='preview-image'
        />

        <div
          className="prev-avail"
          onClick={!availabilityUpdateStatus
            ? handleToggleItemAvailability : null
          }
        >
          {availabilityUpdateStatus ? (
            <p><Loader height='90%' /></p>
          ) : (
            item.availability ? (
              <p style={{color: "seagreen"}}>Available</p>
            ) : (
              <p style={{color: "tomato"}}>Not Available</p>
            )
          )}
        </div>
      </div>

      <div className="pr-item-data">
        <div className="pr-name-edit">
          <p>{item.name}</p>

          <button
            onClick={handleEditItemButtonClick}
            className="edit-item-btn"
          >
            {/*<FontAwesomeIcon*/}
            {/*  className="icon"*/}
            {/*  icon={faPen}*/}
            {/*/>*/}
          </button>

          <button
            onClick={handleItemDeletion}
            className="del-item-btn"
          >
            {/*<FontAwesomeIcon*/}
            {/*  className="icon"*/}
            {/*  icon={faTrashCan}*/}
            {/*/>*/}
          </button>
        </div>

        <div className="pr-variant">
          <PreviewVariants
            variants={item.variants}
          />
        </div>

        <div className="pr-description">
          <PreviewDescription
            description={item.description}
          />
        </div>
      </div>
    </div>
  );
}

PreviewItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  setItemToDelete: PropTypes.func.isRequired
};