import React, { useRef, useState } from "react";
import "../../styles/Register/InputShopImages.scss";
import { useRegister } from "../../hooks/index.js";
import { FixedPopUp, ImageCropInput } from "../../components/index.js";


export default function InputShopImages() {
  const register = useRegister();
  const {
    shopCoverImage, setShopCoverImage,
    shopLogoImage, setShopLogoImage,
    shopCoverImageUrl, setShopCoverImageUrl,
    shopLogoImageUrl, setShopLogoImageUrl,
    moreImages, setMoreImages,
    // shopThemeColors, setShopThemeColors,
    checkIsMoreImagesEmpty, checkIsMoreImagesFilled
  } = register;

  const [selectCoverPhoto, setSelectCoverPhoto] = useState(false);
  const [selectShopLogo, setSelectShopLogo] = useState(false);
  const moreImagesInputRef = useRef(null);

  const handleAddCoverPhotoOnClick = () =>
    setSelectCoverPhoto(!selectCoverPhoto);

  const handleAddShopLogoOnClick = () =>
    setSelectShopLogo(!selectShopLogo);

  // const handleShopThemeColorChange = (event, key) =>
  //   setShopThemeColors({ ...shopThemeColors, [key]: event.target.value });

  const handleChooseImageFileButtonClick = () => {
    moreImagesInputRef.current.click();
  };

  const handleImagesFileSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    let newMoreImages = [...moreImages];
    let imageFillIndex = 0;
    newMoreImages.map(image => {
      if (!image.imageUrl && (imageFillIndex < selectedImages.length)) {
        image.image = selectedImages[imageFillIndex++];
        image.imageUrl = URL.createObjectURL(image.image);
      }
    });
    setMoreImages(newMoreImages);
  };

  return (
    <div className="input-shop-images">

      {/*input shop cover photo and shop logo*/}
      <div className='profile-image-inputs'>

        {/*input shop cover photo*/}
        <div className='cover-photo-container'>
          {shopCoverImageUrl ? (
            <>
              <img src={shopCoverImageUrl} alt='shop cover' />
              <button
                className='change-cover-image-btn'
                onClick={handleAddCoverPhotoOnClick}
              >
                {/*<FontAwesomeIcon icon={faImage} className='icon' />*/}
                <p>Change Shop Cover Image</p>
              </button>
            </>
          ) : (
            <button
              className='upload-cover-image-btn'
              onClick={handleAddCoverPhotoOnClick}
            >
              {/*<FontAwesomeIcon icon={faImage} className='icon' />*/}
              <p>Upload Shop Cover Image</p>
            </button>
          )}
        </div>

        {/*input shop logo*/}
        <div className='shop-logo-container'>
          {shopLogoImageUrl ? (
            <>
              <img src={shopLogoImageUrl} alt='shop logo' />
              <button
                className='change-logo-image-btn'
                onClick={handleAddShopLogoOnClick}
              >
                {/*<FontAwesomeIcon icon={faStore} className='icon' />*/}
                <p>Change Shop Logo Image</p>
              </button>
            </>
          ) : (
            <button
              className='upload-logo-image-btn'
              onClick={handleAddShopLogoOnClick}
            >
              {/*<FontAwesomeIcon icon={faStore} className='icon' />*/}
              <p>Upload Shop Logo Image</p>
            </button>
          )}
        </div>
      </div>

      <div
        className='right-container'
        style={{ minHeight: (moreImages.length) ? null : 60 }}
      >
        <div className='more-images-container'>
          {moreImages.map((image, index) => image.imageUrl ? (
            <div
              key={`image-container-${index}`}
              className="image-container"
            >
              <img src={image.imageUrl} alt={`Image ${index}`} />
              <button
                className="remove-image-btn"
                onClick={() => {}}
              >
                {/*<FontAwesomeIcon icon={faCircleXmark} className="icon"/>*/}
              </button>
            </div>
          ) : (
            <div key={`image-container-${index}`}></div>
          )
          )}
        </div>

        {checkIsMoreImagesEmpty() && (
          <button
            className="upload-more-images-btn-initial"
            onClick={handleChooseImageFileButtonClick}
          >
            {/*<FontAwesomeIcon icon={faImages} className="icon"/>*/}
            <p>Upload More Images</p>
          </button>
        )}

        {!checkIsMoreImagesEmpty() && !checkIsMoreImagesFilled() && (
          <button
            className="upload-more-images-btn"
            onClick={handleChooseImageFileButtonClick}
          >
            {/*<FontAwesomeIcon icon={faImages} className="icon"/>*/}
            <p>Add More Images</p>
          </button>
        )}

        <input
          style={{ display: "none" }}
          ref={moreImagesInputRef}
          type="file"
          accept="image/*"
          onChange={handleImagesFileSelect}
          multiple
        />
      </div>

      {selectCoverPhoto && (
        <FixedPopUp>
          <div className="image-crop-input-container">
            <ImageCropInput
              aspectRatio={11/4}
              image={shopCoverImage}
              setImage={setShopCoverImage}
              imageUrl={shopCoverImageUrl}
              setImageUrl={setShopCoverImageUrl}
              removePopUpFunction={handleAddCoverPhotoOnClick}
              placeholder="Select Cover Image"
            />
          </div>
        </FixedPopUp>
      )}

      {selectShopLogo && (
        <FixedPopUp>
          <div className="image-crop-input-container">
            <ImageCropInput
              aspectRatio={1}
              image={shopLogoImage}
              setImage={setShopLogoImage}
              imageUrl={shopLogoImageUrl}
              setImageUrl={setShopLogoImageUrl}
              removePopUpFunction={handleAddShopLogoOnClick}
              placeholder="Select Shop Logo"
            />
          </div>
        </FixedPopUp>
      )}
    </div>
  );
}