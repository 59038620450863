import React from "react";
import "../../styles/Home/WhyVentory.scss";
import { HomeDemo } from "../../assets/home/demos/index.js";


export default function WhyVentory() {
  return (
    <div className="why-ventory">
      <div className='why-ventory-left'>
        <div className='head'>
          <p className="title">Why Ventory ?</p>

          <p className='heading'>Because we care about your business.</p>
        </div>

        <div className='description'>
          <p>
            Our tool helps shopkeepers easily manage
            inventory and create digital menus so customers can scan and order
            effortlessly. With our IoT devices, shop management is simpler and
            more efficient than ever. Ventory makes running your business
            smoother and boosts customer convenience.
          </p>
        </div>
      </div>

      <div className='why-ventory-right'>
        <img src={String(HomeDemo)} alt='Venotry Image' />
      </div>
    </div>
  );
}