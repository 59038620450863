// function to capitalize first character of each word in a sentence
// export function capitalizeEachWord(str){
//   const words = str.split(" ");
//   const capitalizedWords = words.map((word) => {
//     return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
//   });
//   return capitalizedWords.join(" ");
// }

export function validateEmailId(emailId) {
  return emailId.replace(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "") === "";
}

export function isString(value) {
  return typeof value === "string";
}