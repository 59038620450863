import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../../../../styles/Dashboard/ManageInventory/AddEditItem/SelectImagesPanel.scss";
import { ImageCropInput, PopUp } from "../../../../components/index.js";
import { isNumber } from "../../../../utils/index.js";
import defaultItemImage from "../../../../assets/dashboard/default-select-item.svg";


export default function SelectImagesPanel(props) {
  const { itemImages, setItemImages } = props;
  const [images, setImages] = useState([]);
  const [inputImageIndex, setInputImageIndex] = useState(null);

  useEffect(() => {
    setImages(itemImages.length ? itemImages : [{ image: null, imageUrl: "" }]);
  }, [itemImages]);

  const handleSetImage = (index, image) => {
    let newImages = [...images];
    newImages[index].image = image;
    setImages(newImages);
    setItemImages(newImages);
  };

  const handleSetImageUrl = (index, imageUrl) => {
    let newImages = [...images];
    newImages[index].imageUrl = imageUrl;
    setImages(newImages);
    setItemImages(newImages);
  };

  // const addNewImageInput = () => {
  //   if (images.length < 10) {
  //     let newImages = [...images];
  //     newImages.push({ image: null, imageUrl: "" });
  //     setImages(newImages);
  //   }
  // };

  const removeImageInput = (index) => {
    let newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    setItemImages(newImages);
  };

  const toggleSelectImage = (index) =>
    setInputImageIndex(isNumber(inputImageIndex) ? null : index);

  return (
    <div className="select-images-panel">
      {images.map((image, imageIndex) => (
        <div className="image-input" key={`image-input-${imageIndex}`}>
          <div
            className="select-image-btn"
            style={{
              borderColor: image.imageUrl ? "#39ac6c" : null
            }}
            onClick={() => toggleSelectImage(imageIndex)}
          >
            <img
              src={ image.imageUrl ? image.imageUrl : defaultItemImage}
              alt={`input-image-${imageIndex}`}
            />
          </div>

          <div className="input-prompt">
            <p>
              {(imageIndex === 0)
                ? image.imageUrl ? "Edit featured image" : "Add featured image"
                : image.imageUrl
                  ? `Edit image ${imageIndex + 1}`
                  : `Add image ${imageIndex + 1}`
              }
            </p>

            {(imageIndex !== 0) ? (
              <div
                className="remove-image-btn"
                onClick={() => removeImageInput(imageIndex)}
              >
                {/*<FontAwesomeIcon className="icon" icon={faTrashCan} />*/}
              </div>
            ) : null}
          </div>
        </div>
      ))}

      {(images.length < 10) && (
        <div className="add-new-image-input-btn">
          {/*<FontAwesomeIcon*/}
          {/*  className="icon"*/}
          {/*  icon={faCirclePlus}*/}
          {/*  onClick={addNewImageInput}*/}
          {/*/>*/}
        </div>
      )}

      {isNumber(inputImageIndex) ? (
        <PopUp className="pop-up">
          <div className="image-crop-input-container">
            <ImageCropInput
              removePopUpFunction={toggleSelectImage}
              aspectRatio={1}
              image={images[inputImageIndex].image}
              setImage={(image) => handleSetImage(inputImageIndex, image)}
              imageUrl={images[inputImageIndex].imageUrl}
              setImageUrl={(imageUrl) => handleSetImageUrl(inputImageIndex, imageUrl)}
              placeholder={undefined}
            />
          </div>
        </PopUp>
      ) : null}
    </div>
  );
}

SelectImagesPanel.propTypes = {
  itemImages: PropTypes.array.isRequired,
  setItemImages: PropTypes.func.isRequired
};