import React from "react";
import "../../styles/Register/index.scss";
import { useRegister } from "../../hooks/index.js";
import InputContainer from "./InputContainer.js";
import VerifyCredentials from "./VerifyCredentials.js";
import InputShopDetails from "./InputShopDetails.js";
import InputShopImages from "./InputShopImages.js";
import InputContactDetails from "./InputContactDetails.js";
import InputGSTDetails from "./InputGSTDetails/index.js";
import {
  MaxWidthContainer, NavBar
} from "../../components/index.js";


export default function Register() {
  const register = useRegister();
  const {
    stepsToRegisterInfo, currentStepNumber,
    goBackToPrevStep, moveToNextStep, isLoading
  } = register;

  return (
    <div className="register">
      <div className="nav-bar-container">
        <MaxWidthContainer>
          <NavBar profile />
        </MaxWidthContainer>
      </div>

      <div className="register__wrapper">
        <MaxWidthContainer>
          <InputContainer
            stepsToRegisterInfo={stepsToRegisterInfo}
            currentStepNumber={currentStepNumber}
            prevStepButtonOnClick={goBackToPrevStep}
            nextStepButtonOnClick={moveToNextStep}
            isLoading={isLoading}
          >
            { currentStepNumber === 1 ? (<InputGSTDetails />)
              : currentStepNumber === 2 ? (<InputShopDetails />)
                : currentStepNumber === 3 ? (<InputContactDetails />)
                  : currentStepNumber === 4 ? (<div></div>)
                    : currentStepNumber === 5 ? (<InputShopImages />)
                      : (<VerifyCredentials />)}
          </InputContainer>
        </MaxWidthContainer>
      </div>
    </div>
  );
}
