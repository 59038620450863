import React from "react";
import "../../../styles/Dashboard/ManageProfile/index.scss";


export default function ManageProfile() {
  return (
    <div className="profile">
      <main></main>
    </div>
  );
}