import { db } from "../../configs/index.js";

export const getDefaultCategories = async () => {
  const doc = await db
    .collection("defaults")
    .doc("default_categories")
    .get();

  if (doc.exists) {
    return doc.data();
  } else {
    return {"Loading Categories...": {}};
  }
};