import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/Dashboard/index.scss";
import { Loader } from "../../components/index.js";
import { useDashboard } from "../../hooks/index.js";
import {
  ManageInventoryProvider
} from "../../providers/index.js";
import SideBar from "./SideBar/index.js";
import Home from "./Home/index.js";
import ManageInventory from "./ManageInventory/index.js";
import ManageOffers from "./ManageOffers/index.js";
import CustomerBilling from "./CustomerBilling/index.js";
import ManageOrders from "./ManageOrders/index.js";
import Analytics from "./Analytics/index.js";
import ManageProfile from "./ManageProfile/index.js";


export default function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const dashboard = useDashboard();
  const inventoryList = dashboard.inventoryList;

  const [selectedTaskWindow, setSelectedTaskWindow] = useState("");

  useEffect(() => {
    if (inventoryList.length) {
      dashboard.changeInventory(inventoryList[0]);
    } else {
      navigate("/register");
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setSelectedTaskWindow(queryParams.get("v"));
  }, [location.search]);

  if (dashboard.isLoading){
    return (<Loader />);
  }

  return (
    <div className="dashboard">
      <div className="left-window">
        <SideBar />
      </div>

      <div className="right-window">
        {selectedTaskWindow === "manage-inventory" ? (
          <ManageInventoryProvider>
            <ManageInventory />
          </ManageInventoryProvider>
        ) : selectedTaskWindow === "manage-offers" ? (
          <ManageOffers />
        ) : selectedTaskWindow === "customer-billing" ? (
          <CustomerBilling />
        ) : selectedTaskWindow === "manage-orders" ? (
          <ManageOrders />
        ) : selectedTaskWindow === "analytics" ? (
          <Analytics />
        ) : selectedTaskWindow === "manage-profile" ? (
          <ManageProfile />
        ) : (
          <div><Home /></div>
        )}
      </div>
    </div>
  );
}
