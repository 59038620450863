import React, { useState } from "react";
import PropTypes from "prop-types";
import "../styles/components/GoogleVerifier.scss";
import { Loader } from "./index.js";
import { authenticateWithGoogle } from "../utils/index.js";
import googleIcon from "../assets/register/google-icon.svg";


export default function GoogleVerifier(props) {
  const { onGoogleTokenChange, authType } = props;
  const [
    isSignUpWithGoogleButtonClicked, setIsSignUpWithGoogleButtonClicked
  ] = useState(false);

  const handleSignUpWithGoogleButtonClick = async () => {
    setIsSignUpWithGoogleButtonClicked(true);
    const googleAuthResponse = await authenticateWithGoogle();
    if (googleAuthResponse.success) {
      onGoogleTokenChange(googleAuthResponse.elevated_access_token);
    }
    setIsSignUpWithGoogleButtonClicked(false);
  };

  return (
    <div className="google-verifier">
      <button
        onClick={handleSignUpWithGoogleButtonClick}
        disabled={isSignUpWithGoogleButtonClicked}
      >
        {isSignUpWithGoogleButtonClicked ? (
          <>
            <Loader height="60%" color="dodgerblue" />

            <p>
              Signing {(authType === "sign-up") ? "up" : "in"} with Google...
            </p>
          </>
        ) : (
          <>
            <img src={String(googleIcon)} alt="google-icon"/>

            <p> Sign {(authType === "sign-up") ? "up" : "in"} with Google </p>
          </>
        )}
      </button>
    </div>
  );
}

GoogleVerifier.propTypes = {
  onGoogleTokenChange: PropTypes.func.isRequired,
  authType: PropTypes.string.isRequired
};

GoogleVerifier.defaultProps = {
  cancelButtonType: "sign-in"
};